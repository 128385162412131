<template>
	<div>
		<Header2022 :navbg='true'></Header2022>
		<div class="content">
			<Banner :bannerList="bannerList" height="100vh"></Banner>
			<div class="main1">
				<div class="page">
					<div class="title-wrap">
						<div class="title">绿色低层庭院式住宅</div>
						<div class="title">小院生活在渭南市</div>
						<div class="title">第一个<span>低层花园</span>社区</div>
						<div class="title">紧邻渭南中学</div>
						<div class="title">距离渭南北站</div>
						<div class="title">直线距离仅6.3公里</div>
					</div>
					<div class="img-wrap">
						<img src="../../../../assets/images/project/future-city/bg1.jpg" alt="">
					</div>
				</div>
			</div>

			<div class="main2">
				<div class="page">
					<div class="image-title">
						<img src="../../../../assets/images/project/future-city/jiangxin.png" alt="">
					</div>
					<div class="list">
						<div class="item" v-for="(item,index) in houseTypeList" :key="index">
							<div class="area">{{item.name}}</div>
							<div class="desc">{{item.desc1}}</div>
							<div class="desc" v-if="item.desc2">{{item.desc2}}</div>
						</div>
					</div>
				</div>
			</div>

			<div class="main3">
				<div class="page">
					<div class="title-wrap">
						心有境界 院无界
					</div>
					<div class="desc-wrap">
						<div class="desc">赠送南北双花园</div>
						<div class="desc">前院为私家花园，后院为私家停车位</div>
						<!-- <div class="desc">配备停车位</div> -->
						<div class="desc">可安装充电桩</div>
						<div class="desc">户户都可方便使用新能源汽车</div>
					</div>
					<div class="image-wrap">
						<div class="item">
							<img src="../../../../assets/images/shuang1.png" alt="">
							<div class="title">室外花园</div>
						</div>
						<div class="item">
							<img src="../../../../assets/images/shuang2.png" alt="">
							<div class="title">会客空间</div>
						</div>
						<div class="item">
							<img src="../../../../assets/images/shuang3.png" alt="">
							<div class="title">儿童活动</div>
						</div>
						<div class="item">
							<img src="../../../../assets/images/shuang4.png" alt="">
							<div class="title">宠物之家</div>
						</div>
					</div>

				</div>
			</div>

			<div class="main4">
				<div class="page">
					<div class="icon-list-wrap">
						<div class="icon-wrap" v-for="(item,index) in iconList" :key="index">
							<div class="iconfont" :class="item.icon"></div>
							<div class="name">{{item.name}}</div>
							<div class="desc">{{item.desc}}</div>
						</div>
					</div>
				</div>
			</div>

			<div class="main5">
				<div class="page">
					<div class="title">优质房源</div>
					<div class="img-wrap">
						<img src="../../../../assets/images/project/future-city/youzhi.jpg" alt="">
					</div>
				</div>
			</div>

			<div class="icon main6">
				<div class="page">
					<div class="warp">
						<div class="item">
							<i class="iconfont icon-safe"></i>
							<div class="title">抗震性最好</div>
							<div class="desc">短肢剪力墙结构</div>
							<div class="desc">现浇结构至少30％</div>
						</div>
						<div class="item">
							<i class="iconfont icon-punch"></i>
							<div class="title">降板同层排水</div>
							<div class="desc">
								洁具位置不受限
							</div>
							<div class="desc">低噪声+舒适高度+维修便捷</div>
						</div>
						<div class="item">
							<i class="iconfont icon-brand"></i>
							<div class="title">热水循环</div>
							<div class="desc">
								多点位同时供应
							</div>
							<div class="desc">舒适，节水，节能</div>
						</div>
						<div class="item">
							<i class="iconfont icon-we"></i>
							<div class="title">断桥铝窗外开窗</div>
							<div class="desc">
								气密性更好

							</div>
							<div class="desc">不占室内空间</div>
						</div>
					</div>
					<div class="warp">
						<div class="item">
							<i class="iconfont icon-square"></i>
							<div class="title">VELUX天窗</div>
							<div class="desc">
								引光入室+增加自然通风

							</div>
							<div class="desc">防水更好，可定制升级</div>
						</div>
						<div class="item">
							<i class="iconfont icon-evaluate"></i>
							<div class="title">新风预留</div>
							<div class="desc">
								净化室内空气

							</div>
							<div class="desc">除菌、消毒、祛湿</div>
						</div>
						<div class="item">
							<i class="iconfont icon-flashlightopen"></i>
							<div class="title">空气源热泵</div>
							<div class="desc">
								温度波动小，无风口

							</div>
							<div class="desc">冬夏一套设备</div>
						</div>
						<div class="item">
							<i class="iconfont icon-shop"></i>
							<div class="title">光伏屋面</div>
							<div class="desc">
								绿色低碳

							</div>
							<div class="desc">节能减耗</div>
						</div>
					</div>
				</div>
			</div>

		</div>
		<Footer2022></Footer2022>
	</div>
</template>

<script>
import Header2022 from '@/components/header2022.vue'
	import Footer2022 from '@/components/footer2022.vue'
	import Banner from "@/components/banner.vue"
	import utils from '@/common/utils'
	export default {
		data() {
			return {
				bannerList: [{
					image: require("../../../../assets/images/project/future-city/banner.jpg"),
					titleImage: require("../../../../assets/images/project/future-city/banner-title.png"),
				}, ],
				houseTypeList: [{
						name: "A户型",
						desc1: "建筑面积约105㎡",
						desc2: "可扩展面积至196㎡",
					},
					{
						name: "C1户型",
						desc1: "建筑面积约160㎡",
						desc2: "可扩展面积至252㎡",
					},
					{
						name: "D1户型",
						desc1: "建筑面积约217㎡",
						desc2: "可扩展面积至327㎡",
					},
					{
						name: "E1户型",
						desc1: "建筑面积约210㎡",
						desc2: "可扩展面积至320㎡",
					},
					{
						name: "F1户型",
						desc1: "建筑面积约148㎡",
						desc2: "可扩展面积至231㎡",
					},
					{
						name: "小院商居1",
						desc1: "建筑面积约210㎡",
						desc2: "可扩展面积至293㎡",
					},
					{
						name: "B户型",
						desc1: "建筑面积约138㎡",
						desc2: "可扩展面积至222㎡",
					},
					{
						name: "C2户型",
						desc1: "建筑面积约163㎡",
						desc2: "可扩展面积至258㎡",
					},
					{
						name: "D2户型",
						desc1: "建筑面积约211㎡",
						desc2: "可扩展面积至321㎡",
					},
					{
						name: "E2户型",
						desc1: "建筑面积约200㎡",
						desc2: "可扩展面积至310㎡",
					},
					{
						name: "F2户型",
						desc1: "建筑面积约150㎡",
						desc2: "可扩展面积至233㎡",
					},
					{
						name: "小院商居2",
						desc1: "建筑面积约212㎡",
						desc2: "可扩展面积至295㎡",
					},
					{
						name: "小院旺铺",
						desc1: "建筑面积约60㎡",
						// desc2: "可扩展面积至295㎡",
					},
				],
				iconList: [{
						icon: "icon-structure",
						name: "剪力墙结构",
						desc: "安全抗震性极高"
					},
					{
						icon: "icon-Aluminiumalloysvg",
						name: "断桥铝合金",
						desc: "坚固耐久、隔音隔热、抗风压"
					},
					{
						icon: "icon-circulatorysystem",
						name: "热水循环系统",
						desc: "实现热水即开即用"
					}
				],
			}
		},
		components: {
			Banner,
			Header2022,
			Footer2022,
		},
		methods: {
			houseType: utils.debounce(function(e) {
				this.$router.push({
					name: 'productDooryardDetail',
					params: {
						houseType: e
					}
				});
			}, 500, true),

			projectClick: utils.debounce(function(name) {
				this.$router.push({
					name
				});
			}, 500, true),

		}
	}
</script>

<style lang="scss" scoped>
	.page {
		width: 1200px;
		margin: 0 auto;
	}

	.icon {
		background-color: #fff;
		padding: 100px 0 118px 0;
	}

	.icon .warp {
		display: flex;
		justify-content: space-between;
		margin-bottom: 60px;
	}

	.icon .warp:last-of-type {
		margin-bottom: 0;
	}

	.icon .warp .item {
		width: 200px;
		text-align: center;
		color: #303233;
	}

	.icon .warp .item .iconfont {
		font-size: 64px;
		line-height: 64px;
	}

	.icon .warp .item .title {
		font-size: 24px;
		margin-top: 40px;
		margin-bottom: 20px;
	}

	.icon .warp .item .desc {
		font-size: 14px;
		color: #666666;
	}

	.main1 {
		background-color: #fff;

		.title-wrap {
			text-align: center;
			font-size: 24px;
			color: #292929;
			line-height: 24px;

			.title:nth-of-type(1) {
				font-size: 40px;
				color: #303233;
				font-weight: 900;
				margin-top: 100px;
				line-height: 40px;
			}

			.title:nth-of-type(2) {
				margin-top: 63px;
			}

			.title:nth-of-type(3) {
				margin-top: 10px;

				span {
					color: #303233;
					font-weight: 600;
				}
			}

			.title:nth-of-type(4) {
				margin-top: 30px;
			}

			.title:nth-of-type(5) {
				margin-top: 30px;
			}

			.title:nth-of-type(6) {
				margin-top: 10px;
			}
		}

		.img-wrap {
			margin-top: 44px;
			width: 100%;

			img {
				width: 100%;
			}
		}
	}

	.main2 {
		background-image: url('../../../../assets/images/project/future-city/jiangxin.jpg');
		background-size: 100% 100%;
		background-position-x: no-repeat;
		padding-top: 100px;
		padding-bottom: 80px;

		.image-title {
			text-align: center;
			width: 100%;

			img {
				width: 367px;
			}
		}

		.list {
			display: flex;
			margin-top: 80px;
			width: 100%;
			justify-content: space-around;
			color: #dddddd;
			flex-wrap: wrap;

			.item {
				width: 180px;
				// border-right: 1px solid #777777;
				text-align: center;
				margin-bottom: 60px;

				.area {
					background-color: #fff;
					border-radius: 100px;
					font-size: 22px;
					font-weight: 900;
					width: 125px;
					height: 32px;
					line-height: 32px;
					text-align: center;
					color: #444451;
					margin: 0 auto 18px;
				}

				.desc {
					font-size: 18px;
					margin-bottom: 14px;
					line-height: 18px;
				}

				.desc:last-of-type {
					margin-bottom: 0;
				}

				&:last-of-type {
					border-right: none;
				}
			}
		}
	}

	.main3 {
		padding: 100px 0;

		.title-wrap {
			width: 100%;
			font-size: 40px;
			font-weight: 900;
			color: #454552;
			text-align: center;
		}

		.desc-wrap {
			margin-top: 56px;
			font-size: 18px;
			color: #454552;
			text-align: center;
			line-height: 30px;
		}

		.image-wrap {
			margin-top: 62px;
			display: flex;
			justify-content: space-between;

			.item {
				width: 285px;
				position: relative;

				.title {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 24px;
					color: #fff;
					font-weight: 400;
					background-color: rgba(0, 0, 0, 0.5);
				}
			}
		}
	}

	.main4 {
		background-image: url('../../../../assets/images/project/future-city/bg3.jpg');
		background-size: 100% 100%;
		background-repeat: no-repeat;

		.icon-list-wrap {
			width: 100%;
			display: flex;
			padding: 100px 0 87px;
			color: #fff;

			.icon-wrap {
				flex: 1;
				text-align: center;

				.iconfont {
					font-size: 56px;
				}

				.name {
					margin-top: 38px;
					font-size: 24px;
					line-height: 24px;
				}

				.desc {
					margin-top: 17px;
					font-size: 18px;
					line-height: 18px;
				}
			}
		}
	}

	.main5 {
		padding: 100px 0;

		.title {
			font-size: 40px;
			font-weight: 600;
			color: #454552;
			line-height: 40px;
			text-align: center;
		}

		.img-wrap {
			margin-top: 60px;
			width: 100%;

			img {
				width: 100%;
			}
		}
	}


	.main6 {
		background-color: #F6F6F6;
		margin-bottom: 96px;
	}
</style>
